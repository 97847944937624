import React, {useCallback, useState} from 'react';
import Loader from '../loader';

const Input = ({
  id,
  label,
  error,
  errorClass,
  containerClass,
  className,
  onChangeText,
  onBlur,
  formatText,
  loading,
  maxLength,
  disabled,
  placeholder,
  value = '',
  type = 'text',
  inputMode = 'text',
  formControlStyle = {},
}) => {
  const [v, setValue] = useState('');

  const onChange = useCallback(
    ({target}) => {
      let temp = target.value;
      if (typeof formatText === 'function') temp = formatText(temp);
      setValue(temp);
      if (typeof onChangeText === 'function') onChangeText(temp);
    },
    [onChangeText, formatText],
  );

  return (
    <>
      <div className={'c_input position-relative ' + containerClass}>
        {label && <div className=" py-1 label">{label}</div>}
        <div className="form-control input_alt" style={formControlStyle} disabled={disabled}>
          <input
            tabIndex={-1}
            id={id}
            type={type}
            onBlur={onBlur}
            disabled={disabled}
            onChange={onChange}
            inputMode={inputMode}
            maxLength={maxLength}
            placeholder={placeholder}
            value={value.length > 0 ? value : v}
            className={'px-0 form-control clear ' + className}
          />
          {loading && <Loader className="loading" size="sm" />}
        </div>
        {error && <small className={'text-danger ' + errorClass}>{error}</small>}
      </div>
    </>
  );
};

export default Input;
